<template>
    <div :class="$style.carouselCard" class="column is-one-quarter">
        <div :class="$style.header">
            <!-- <img
                :class="$style.headerImage"
                :alt="title"
                :src="headerImage"> -->
            <img v-if="thumbnailSrc" :class="$style.headerImage" :src="thumbnailSrc">
            <img v-else :class="$style.imageIcon" alt="" src="@/assets/no-thumbnail.png" />
        </div>
        <div :class="$style.cardBody">
            <div :class="$style.content">
                <div :class="$style.title">
                    <div :class="$style.productList">
                        <div :class="$style.role">
                            {{ title }}
                        </div>
                    </div>
                    <div :class="lastViewed">
                        {{ lastViewed }}
                    </div>
                </div>
                <div v-if="badges && badges.length > 0" :class="$style.socialIcons">
                    <Badge
                        v-for="badge in badges"
                        :key="badge.title"
                        :title="badge.title"
                        :color="badge.color" />
                </div>
                <div v-else :class="$style.socialIcons">
                    <Badge
                        key="NoTags"
                        title="No Tags"
                        color="green" />
                </div>
            </div> 
        </div> 
    </div>
</template>

<script>
import Badge from './Badge.vue';

export default {
    name: 'CarouselCard',
    // Your component's logic goes here
    props: {
        thumbnailSrc: {
            type: String
        },
        title: {
            type: String,
            required: true
        },
        lastViewed: {
            type: String,
            required: false
        },
        headerImage: {
            type: String,
            required: true,
            default: ''
            },
        badges: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        resolvedImagePath() {
            return require(`${this.headerImage}`);
        }
    },
    components: {Badge},
    mounted() {
        console.log("This is the value of headerImage: " + this.headerImage);
    }
};

</script>

<style module>
.carouselCard {
flex: 1;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
border-radius: 8px;
background-color: #fff;
height: 401px;
overflow: hidden;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}

.headerImage {
align-self: stretch;
border-radius: 8px;
max-width: 100%;
overflow: hidden;
height: 192px;
flex-shrink: 0;
object-fit: contain;
}

.header {
align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
padding: 16px 16px 0px;
}

.cardBody {
align-self: stretch;
height: 190px;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
padding: 24px;
box-sizing: border-box;
}

.content {
align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 16px;
}

.title {
align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 32px;
}
.productList {
align-self: stretch;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
}

.role {
width: 226px;
position: relative;
line-height: 150%;
font-weight: 500;
display: flex;
align-items: center;
}

.lastViewed {
align-self: stretch;
position: relative;
line-height: 150%;
font-family: Roboto;
}

.socialIcons {
align-self: stretch;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
text-align: center;
font-size: 12px;
font-family: Roboto;
}
</style>