<template>
    <div :class="$style.applicationUiPrimarySidebar">
        <div :class="$style.content">
            <div :class="$style.logo" />
            <div :class="$style.main">
                <div :class="$style.overview">
                    <div :class="$style.leftContent">
                        <img
                            :class="$style.homeIcon"
                            alt=""
                            src="./eccr/assets/home.svg">
                        <router-link

                            to="/"

                            :class="$style.pages">
                            Home
                        </router-link>
                    </div>
                    <div :class="$style.icon">
                        <img
                            :class="$style.chevronDownIcon"
                            alt=""
                            src="./eccr/assets/chevron-down.svg">
                    </div>
                </div>
                <div :class="$style.pages1">
                    <div :class="$style.leftContent">
                        <img
                            :class="$style.homeIcon"
                            alt=""
                            src="./eccr/assets/file.svg">
                        <div :class="$style.pages">
                            Pages
                        </div>
                    </div>
                    <img
                        :class="$style.chevronUpIcon"
                        alt=""
                        src="./eccr/assets/chevron-down.svg">
                </div>
                <div :class="$style.multidropdown">
                    <div :class="$style.sales">
                        <div :class="$style.leftContent">
                            <img
                                :class="$style.homeIcon"
                                alt=""
                                src="./eccr/assets/file.svg">
                                                   
                            <!-- Framework Dropdown -->
                            <div
                                :class="$style.menuItem"
                                @click="toggleDropdown('frameworkDropdown')">
                                <div :class="$style.menuTitle">
                                    Framework
                                    <img
                                        :class="$style.chevronUpIcon"
                                        alt=""
                                        src="./eccr/assets/chevron-down.svg">
                                    <div :class="[$style.dropdown, { [$style.open]: isDropdownOpen('frameworkDropdown') }]">
                                        <router-link
                                            to="/catalog"
                                            :class="$style.dropdownItem">
                                            Catalog
                                        </router-link>
                                        <router-link
                                            to="/import"
                                            :class="$style.dropdownItem">
                                            Import
                                        </router-link>
                                        <router-link
                                            to="/crosswalk"
                                            :class="$style.dropdownItem">
                                            Crosswalk
                                        </router-link>
                                        <router-link
                                            to="/frameworks"
                                            :class="$style.dropdownItem">
                                            Frameworks (Old)
                                        </router-link>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="$style.authentication">
                        <div :class="$style.leftContent">
                            <img
                                :class="$style.homeIcon"
                                alt=""
                                src="./eccr/assets/code-branch.svg">
                            <div :class="$style.pages">
                                <router-link
                                    to="/concepts"
                                    :class="$style.dropdownItem">
                                    Taxonomies
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <!-- <div :class="$style.authentication">
                        <div :class="$style.leftContent">
                            <img
                                :class="$style.homeIcon"
                                alt=""
                                src="./eccr/assets/badge-check.svg">
                            <div :class="$style.pages">
                                Assertion
                            </div>
                        </div>
                        <img
                            :class="$style.chevronUpIcon"
                            alt=""
                            src="./eccr/assets/chevron-down.svg">
                    </div> -->
                </div>
                <div :class="$style.multidropdown">
                    <div :class="$style.authentication">
                        <div :class="$style.leftContent">
                            <img
                                :class="$style.homeIcon"
                                alt=""
                                src="./eccr/assets/book.svg">

                            <router-link
                                to="/docs"
                                :class="$style.pages">
                                Docs
                            </router-link>
                        </div>
                        <div :class="$style.icon">
                            <img
                                :class="$style.chevronDownIcon"
                                alt=""
                                src="./eccr/assets/chevron-down.svg">
                        </div>
                    </div>
                    <div :class="$style.pages1">
                        <div :class="$style.icon">
                            <img
                                :class="$style.chevronDownIcon"
                                alt=""
                                src="./eccr/assets/chevron-down.svg">
                        </div>
                    </div>
                    <div :class="$style.authentication">
                        <div :class="$style.leftContent">
                            <img
                                :class="$style.homeIcon"
                                alt=""
                                src="./eccr/assets/life-buoy.svg">
                            <div :class="$style.pages">
                                Help
                            </div>
                        </div>
                        <div :class="$style.icon">
                            <img
                                :class="$style.chevronDownIcon"
                                alt=""
                                src="./eccr/assets/chevron-down.svg">
                        </div>
                    </div>
                </div>
                <div :class="$style.cta">
                    <div :class="$style.content1">
                        <div :class="$style.header">
                            <div :class="$style.badge">
                                <div :class="$style.text">
                                    Beta
                                </div>
                            </div>
                            <!-- <div :class="$style.icon4">
                                <img
                                    :class="$style.xOutlineIcon"
                                    alt=""
                                    src="./eccr/assets/x-outline.svg">
                            </div> -->
                        </div>
                        <div :class="$style.text1">
                            <div :class="$style.previewTheNew">
                                We've updated the ECCR interface with a fresh new look and feel to enhance your user experience. Check it out now and explore the improved features—more updates are coming soon!
                            </div>
                            <!-- <div :class="$style.turnNewFeatures">
                                Switch to Legacy CaSS
                            </div> -->
                        </div>
                    </div>
                </div>
                <div :class="$style.sidebarBottomIcons">
                    <div :class="$style.icons">
                        <img
                            :class="$style.homeIcon"
                            alt=""
                            src="./eccr/assets/Github.svg">
                        <img
                            :class="$style.homeIcon"
                            alt=""
                            src="./eccr/assets/LinkedIn.svg">
                        <img
                            :class="$style.homeIcon"
                            alt=""
                            src="./eccr/assets/YoutubePlay.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

    <script >
        
        export default {
    name: 'SideNav',
    data() {
    return {
    openDropdown: null
    };
    },
    methods: {
    toggleDropdown(dropdown) {
    this.openDropdown = this.openDropdown === dropdown ? null : dropdown;
    },
    isDropdownOpen(dropdown) {
    return this.openDropdown === dropdown;
    }
    }
    };
                </script>
                
                <style module>
                

                .menuItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    }

    .menuTitle {
    flex: 1;
    font-weight: bold;
    }

    .chevronIcon {
    width: 16px;
    height: 16px;
    }

    .dropdown {
    display: none;

    }

    .dropdown.open {
    display: block;
    }

    .dropdownItem {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333;
    }

    .dropdownItem:hover {
    background-color: #ddd;
    }

                .logo {
                width: 232px;
                height: 36px;
                display: none;
        }
                .homeIcon {
                    width: 16px;
                    position: relative;
                    height: 16px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
                .pages {
                    position: relative;
                    line-height: 150%;
                    font-weight: 500;
                }
                .leftContent {
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;
                }
                .chevronDownIcon {
                    width: 28px;
                    position: relative;
                    height: 28px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
                .icon {
                    display: none;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                }
                .overview {
                    align-self: stretch;
                    border-radius: 8px;
                    background-color: #f4f3f6;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 6px 8px;
                }
                .pages1 {
                    width: 232px;
                    display: none;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 6px 8px;
                    box-sizing: border-box;
                }
                .chevronUpIcon {
                    width: 12px;
                    position: relative;
                    height: 12px;
                    overflow: hidden;
                    flex-shrink: 0;
                }
                .sales {
                    align-self: stretch;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 6px 8px;
                }
                .productList {
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }
                .submenu {
                    width: 226px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 0px 0px 0px 36px;
                    box-sizing: border-box;
                    gap: 16px;
                }
                .multidropdown {
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 8px;
                }
                .authentication {
                    align-self: stretch;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 6px 8px;
                }
                .main {
                    align-self: stretch;
                    border-bottom: 1px solid #e5e7eb;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0px 0px 24px;
                    gap: 8px;
                }
                .text {
                    position: relative;
                    line-height: 16px;
                    font-weight: 500;
                }
                .badge {
                    border-radius: 6px;
                    background-color: #d5c6e9;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 2px 10px;
                }
                .xOutlineIcon {
                    width: 10px;
                    position: relative;
                    height: 10px;
                }
                .icon4 {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                }
                .header {
                    align-self: stretch;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }
                .previewTheNew {
                    align-self: stretch;
                    position: relative;
                    line-height: 150%;
                }
                .turnNewFeatures {
                    align-self: stretch;
                    position: relative;
                    text-decoration: underline;
                    line-height: 150%;
                    font-family: Inter;
                }
                .text1 {
                    align-self: stretch;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 12px;
                    text-align: left;
                    font-size: 14px;
                    color: #42167a;
                }
                .content1 {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 12px;
                }
                .cta {
                    align-self: stretch;
                    border-radius: 6px;
                    background-color: #e5dcf4;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 16px;
                    text-align: center;
                    font-size: 12px;
                    color: #2d065d;
                    font-family: Roboto;
                }
                .icons {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 20px;
                }
                .sidebarBottomIcons {
                    align-self: stretch;
                    flex: 1;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    justify-content: center;
                }
                .content {
                    align-self: stretch;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 16px 0px;
                    gap: 24px;
                }
                .applicationUiPrimarySidebarOld {
                    height: 100%;
                    width: 100%;
                    position: relative;
                    background-color: #fff;
                    border-right: 1px solid #e8e6eb;
                    box-sizing: border-box;
                    overflow: hidden;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0px 12px;
                    text-align: left;
                    font-size: 16px;
                    color: #111928;
                    font-family: Inter;
                }
                .applicationUiPrimarySidebar {
    width: 20%; /* I made twenty percent, looks good but can be changed*/
    min-width: 16rem; /* Prevents sidebar nav from collapsing */
    background-color: #fff;
    border-right: 1px solid #e8e6eb;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 12px;
    text-align: left;
    font-size: 16px;
    color: #111928;
    font-family: Inter;

    position: fixed;
    height: 100%;
    top: 60px; /* Height of the top navbar */
    left: 0;
    overflow: auto;
    z-index: 999; /* Lower than top-navbar */
    }

    .sidebar-header {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
    }

    .sidebar-logo {
    color: white;
    text-decoration: none;
    }

    .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    }

    .sidebar-item {
    padding: 1rem;
    }

    .sidebar-item a {
    color: white;
    text-decoration: none;
    display: block;
    }

    .sidebar-item a:hover {
    background-color: #575757;
    }
        
        </style>