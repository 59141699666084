<template>
    <div class="container1">
        <div class="titleDesc">
            <b :class="heading">Enterprise Competency and Credential Registry (ECCR)</b>
            <div class="supportingText">
                The hub for authoring and managing your training and education competency frameworks.
            </div>
            <div class="buttons">
                <router-link 
                    class="pages"
                    :to="{path: '/docs', query: queryParams}">
                    <div class="button is-outline is-success">
                        Learn More
                        <img
                            class="arrowRightIcon"
                            alt=""
                            src="@/components/eccr/assets/arrow-right.svg">
                    </div>
                </router-link>
                <div class="button1" />
            </div>
        </div>
        <img
            class="videoIcon"
            alt=""
            src="@/components/eccr/assets/Video.png">
    </div>
</template>

<script>
export default {
    name: 'SpotlightContainer'
    // Your component's logic goes here
};
</script>

<style>

.container1 {
align-self: stretch;
border-radius: 8px;
background-color: #fff;
height: 288px;
overflow: hidden;
flex-shrink: 0;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
padding: 16px;
box-sizing: border-box;
gap: 16px;
font-family: Roboto;
}

.b {
position: relative;
line-height: 24px;
}

.titleDesc {
flex: 1;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding: 16px;
gap: 24px;
}

.heading {
font-size: 22px;
align-self: stretch;
position: relative;
line-height: 28px;
}

.supportingText {
align-self: stretch;
position: relative;
line-height: 150%;
color: #6b7280;
}

.buttons {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
gap: 16px;
font-size: 16px;
color: #fff;
}

.button {
all:unset;
border-radius: 8px;
background-color: #562990;
overflow: hidden;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 12px 20px;
gap: 8px;
}
.button:hover {color:#fff;}
.pages {
position: relative;
line-height: 150%;
font-weight: 500;
}

.arrowRightIcon {
width: 24px;
position: relative;
height: 24px;
overflow: hidden;
flex-shrink: 0;
}

.button1 {
width: 124px;
border-radius: 8px;
border: 1px solid #e8e6eb;
box-sizing: border-box;
height: 50px;
display: none;
}

.videoIcon {
flex: 1;
border-radius: 6px;
max-width: 100%;
overflow: hidden;
height: 244px;
object-fit: cover;
}

</style>

