<script setup>
import Spotlight from '../components/Spotlight.vue';
import CarouselCard from '../components/CarouselCard.vue';
</script>

<template>
    <div :class="$style.container">
        <div :class="$style.content3">
            <div :class="$style.contentLayouts">
                <div :class="$style.contentLayoutsInner">
                    <div :class="$style.pageHeaderWrapper">
                        <div :class="$style.pageHeader">
                            <div :class="$style.welcomeX">
                                {{ welcomeMessage }}
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style.containerParent">
                    <Spotlight />
                    <!-- RECENTLY ACCESSED -->
                    <div :class="$style.headerLgParent" class="columns" v-if="recentCards.length > 0">
                        <div :class="$style.headerLg">
                            <div :class="$style.leftContent8">
                                <div :class="$style.headingPercent">
                                    <b :class="$style.b">Recently Accessed</b>
                                </div>
                                <div :class="$style.thisWeekVisitors">
                                    Hop back into your recently accessed competency frameworks
                                </div>
                            </div>
                            <!-- <div :class="$style.button2">
                                <div :class="$style.pages">
                                    See more
                                </div>
                                <img :class="$style.arrowNarrowRightIcon" alt=""
                                    src='@/components/eccr/assets/arrow-narrow-right.svg'>
                            </div> -->
                        </div>
                        <div :class="$style.carouselWrapper">
                            <div :class="$style.carousel" class="carousel">
                                <div :class="$style.cards">
                                    <CarouselCard
                                        v-for="(card, index) in recentCards"
                                        :class="`item-${index+1}`"
                                        :key="index"
                                        :title="card.title"
                                        :headerImage="card.headerImage"
                                        :badges="card.badges"
                                        :thumbnailSrc="card.thumbnailSrc" />
                                </div>
                                <!-- <div :class="$style.nav">
                                    <img :class="$style.barsIcon" alt=""
                                        src="../components/eccr/assets/arrow-right-outline.svg">
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- MY FAVORITES -->
                    <div :class="$style.headerLgParent" class="columns" v-if="favoriteCards.length > 0">
                        <div :class="$style.headerLg">
                            <div :class="$style.leftContent8">
                                <div :class="$style.headingPercent">
                                    <b :class="$style.b">Bookmarked Frameworks</b>
                                </div>
                                <div :class="$style.thisWeekVisitors">
                                    See your saved or curated competency frameworks within the ECCR.
                                </div>
                            </div>
                            <!-- <div :class="$style.button2">
                                <div :class="$style.pages">
                                    See more
                                </div>
                                <img :class="$style.arrowNarrowRightIcon" alt=""
                                    src='@/components/eccr/assets/arrow-narrow-right.svg'>
                            </div> -->
                        </div>
                        <div :class="$style.carouselWrapper">
                            <div :class="$style.carousel">
                                <div :class="$style.cards">
                                    <CarouselCard
                                        v-for="(card, index) in favoriteCards"
                                        :key="index"
                                        :title="card.title"
                                        :lastViewed="card.lastViewed"
                                        :headerImage="card.headerImage"
                                        :badges="card.badges" 
                                        :thumbnailSrc="card.thumbnailSrc" />
                                </div>
                                <!-- <div :class="$style.nav">
                                    <img :class="$style.barsIcon" alt=""
                                        src="../components/eccr/assets/arrow-right-outline.svg">
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- MY DIRECTORIES -->
                    <div :class="$style.headerLgGroup" v-if="false">
                        <div :class="$style.headerLg1">
                            <div :class="$style.leftContent8">
                                <div :class="$style.headingPercent">
                                    <b :class="$style.b">My Directories</b>
                                </div>
                                <div :class="$style.thisWeekVisitors">
                                    See your saved or curated directories of competency frameworks or create a new
                                    directory
                                </div>
                            </div>
                            <!-- <div :class="$style.button2">
                                <div :class="$style.pages">
                                    See more
                                </div>
                                <img :class="$style.arrowNarrowRightIcon" alt=""
                                    src="@/components/eccr/assets/arrow-narrow-right.svg">
                            </div> -->
                        </div>
                        <div :class="$style.carouselContainer">
                            <div :class="$style.carousel1">
                                <div :class="$style.cards">
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Cybersecurity</b>
                                                        <div :class="$style.role4">
                                                            Viewed 1 hour ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Leadership</b>
                                                        <div :class="$style.role4">
                                                            Viewed 1 hour ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Air Force</b>
                                                        <div :class="$style.role6">
                                                            Viewed 2 hours ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Intelligence</b>
                                                        <div :class="$style.role4">
                                                            Viewed 2 hours ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.nav">
                                    <img :class="$style.barsIcon" alt=""
                                        src="@/components/eccr/assets/plus-outline.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div :class="$style.headerLgGroup">
                        <div :class="$style.headerLg1">
                            <div :class="$style.leftContent8">
                                <div :class="$style.headingPercent">
                                    <b :class="$style.b">Functional Communities</b>
                                </div>
                                <div :class="$style.thisWeekVisitors">
                                    Explore competency frameworks related to the 28 DoD Functional Communities
                                </div>
                            </div>
                            <div :class="$style.button2">
                                <div :class="$style.pages">
                                    See more
                                </div>
                                <img :class="$style.arrowNarrowRightIcon" alt=""
                                    src="@/components/eccr/assets/arrow-narrow-right.svg">
                            </div>
                        </div>
                        <div :class="$style.carouselContainer">
                            <div :class="$style.carousel1">
                                <div :class="$style.cards">
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Cyber</b>
                                                        <div :class="$style.role8">
                                                            Updated 3 months ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Human Resources</b>
                                                        <div :class="$style.role8">
                                                            Updated 4 months ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Intelligence</b>
                                                        <div :class="$style.role10">
                                                            Updated 6 months ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.profileCard2">
                                        <div :class="$style.header1">
                                            <img :class="$style.cardHeaderIcon" alt=""
                                                src="@/components/eccr/assets/card-header.png">
                                        </div>
                                        <div :class="$style.cardBody">
                                            <div :class="$style.productList">
                                                <div :class="$style.productList">
                                                    <div :class="$style.productList">
                                                        <b :class="$style.previewTheNew">Logistics</b>
                                                        <div :class="$style.role8">
                                                            Updated 11 months ago
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.nav">
                                    <img :class="$style.barsIcon" alt=""
                                        src="@/components/eccr/assets/arrow-right-outline.svg">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- POPULAR FRAMEWORKS -->
                    <div :class="$style.headerLgParent" class="columns" v-if="popularCards.length > 0">
                        <div :class="$style.headerLg">
                            <div :class="$style.leftContent8">
                                <div :class="$style.headingPercent">
                                    <b :class="$style.b">Popular Frameworks</b>
                                </div>
                                <div :class="$style.thisWeekVisitors">
                                    Explore popular competency frameworks across the community.
                                </div>
                            </div>
                            <!-- <div :class="$style.button2">
                                <div :class="$style.pages">
                                    See more
                                </div>
                                <img :class="$style.arrowNarrowRightIcon" alt=""
                                    src='@/components/eccr/assets/arrow-narrow-right.svg'>
                            </div> -->
                        </div>
                        <div :class="$style.carouselWrapper">
                            <div :class="$style.carousel">
                                <div :class="$style.cards">
                                    <CarouselCard
                                        v-for="(card, index) in popularCards"
                                        :key="index"
                                        :title="card.title"
                                        :lastViewed="card.lastViewed"
                                        :headerImage="card.headerImage"
                                        :badges="card.badges"
                                        :thumbnailSrc="card.thumbnailSrc" />
                                </div>
                                <!-- <div :class="$style.nav">
                                    <img :class="$style.barsIcon" alt=""
                                        src="../components/eccr/assets/arrow-right-outline.svg">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {cassUtil} from '../mixins/cassUtil';
export default {
    data: () => ({
        availableIdentities: [],
        recentCards: [],
        favoriteCards: [],
        popularCards: []
        // recentCards: [
        //     {
        //         title: "Aerospace Competency Model U.S. Department of Labor (DOL)",
        //         lastViewed: "Viewed 1 hour ago",
        //         headerImage: require("@/components/eccr/assets/card-header.png"),
        //         badges: [
        //             {title: "Leadership", color: "green"},
        //             {title: "DCPAS", color: "purple"}
        //         ]
        //     },
        //     {
        //         title: "Defense Cyber Workforce Framework (DCWF)",
        //         lastViewed: "Viewed 1 hour ago",
        //         headerImage: require("@/components/eccr/assets/card-header.png"),
        //         badges: [
        //             {title: "Cyber", color: "green"},
        //             {title: "NICE", color: "purple"},
        //             {title: "JCT&CS", color: "purple"}
        //         ]
        //     },
        //     {
        //         title: "Civilian Leader Development Continuum Framework",
        //         lastViewed: "Viewed 2 hours ago",
        //         headerImage: require("@/components/eccr/assets/card-header.png"),
        //         badges: [
        //             {title: "Leadership", color: "green"},
        //             {title: "DCPAS", color: "purple"}
        //         ]
        //     },
        //     {
        //         title: "MITRE ATT&CK",
        //         lastViewed: "Viewed 1 day ago",
        //         headerImage: require("@/components/eccr/assets/card-header.png"),
        //         badges: [
        //             {title: "Cyber", color: "green"},
        //             {title: "Army", color: "purple"}
        //         ]
        //     }
        // ]
    }),
    computed: {
        loggedOnPerson: function() {
            return this.$store.getters['user/loggedOnPerson'];
        },
        isLoggedOn: function() {
            if (this.loggedOnPerson && this.loggedOnPerson.name) {
                return true;
            } else {
                return false;
            }
        },
        displayName: function() {
            if (this.isLoggedOn) {
                return this.loggedOnPerson.name;
            } else if (this.availableIdentities.length > 0) {
                return this.availableIdentities[0].displayName ? this.availableIdentities[0].displayName : 'Organization';
            } else {
                return 'No user';
            }
        },
        welcomeMessage: function() {
            let isLogged = this.isLoggedOn; 
            if (isLogged) {
                let name = this.displayName;
                let nameParts = name.split(" ");
                if (nameParts.length > 0) {
                    return `Welcome ${nameParts[0]}!`;
                }
            }
            return "Welcome!";
        }
    },
    methods: {
        getMatchingFramework: function(frameworks, frameworkId) {
            for (let framework of frameworks) {
                if (framework.frameworkURI.startsWith(frameworkId)) {
                    return framework;
                }
            }

            return undefined;
        },
        cardFromFramework: function(framework) {
            console.log("Converting to card: ", framework);
            // key: index++,
            // frameworkLink: me.createFrameworkLink(frameworkObj["@id"] || frameworkObj.id),
            // frameworkName: (frameworkObj.name["@value"] || frameworkObj.name),
            // frameworkURI: frameworkObj["@id"] || frameworkObj.id,
            // frameworkDescription: frameworkObj.description !== undefined ? (frameworkObj.description["@value"] || frameworkObj.description) : "No description.",
            // itemsCount: Array.isArray(frameworkObj.competency) ? frameworkObj.competency.length : 0,
            // publishDate: "Published on " + new Date(frameworkObj["schema:dateCreated"]).toLocaleDateString("en-US", {month: 'short', day: 'numeric', year: 'numeric'}),
            // lastUpdatedDate: me.calculateLastModifiedTime(frameworkObj["schema:dateModified"])
            return {
                title: framework.frameworkName,
                uri: framework.frameworkURI,
                // lastViewed: "Viewed 1 hour ago",
                lastViewed: framework.lastUpdatedTime,
                headerImage: require("@/components/eccr/assets/card-header.png"),
                // badges: [
                //     {title: "Cyber", color: "green"},
                //     {title: "NICE", color: "purple"},
                //     {title: "JCT&CS", color: "purple"}
                // ]
                badges: []
            };
        },
        loadForMe: async function() {
            let frameworks = await cassUtil.methods.getFrameworks();
            let myHistory = await cassUtil.methods.getMyHistory();

            if (frameworks === undefined) {
                frameworks = [];
            }

            frameworks.sort((a, b) => new Date(b.lastUpdatedRaw) - new Date(a.lastUpdatedRaw));
            
            // console.log(frameworks);
            // console.log(myHistory);

            let recCards = [];
            let recentFrameworks = myHistory.recentFrameworks;

            for (let recentURI of recentFrameworks) {
                let matching = this.getMatchingFramework(frameworks, recentURI);
                if (matching !== undefined) {
                    recCards.push(this.cardFromFramework(matching));
                }
            }
            
            // console.log("Recent Cards:", recCards);
            
            let favCards = [];
            let favFrameworks = myHistory.favoriteFrameworks;

            for (let favURI of favFrameworks) {
                let matching = this.getMatchingFramework(frameworks, favURI);
                if (matching !== undefined) {
                    favCards.push(this.cardFromFramework(matching));
                }
            }

            if (recCards.length > 4) {
                let excess = recCards.length - 4;
                recCards.splice(4, excess);
            }
            
            if (favCards.length > 4) {
                let excess = favCards.length - 4;
                favCards.splice(4, excess);
            }
            
            let popCards = frameworks.map(this.cardFromFramework);
            if (popCards.length > 4) {
                let excess = popCards.length - 4;
                popCards.splice(4, excess);
            }

            let allCards = [
                ...recCards,
                ...favCards,
                ...popCards
            ];
            let uniqueMap = {};
            for (let card of allCards) {
                uniqueMap[card.uri] = card;
            }
            let uniqueCards = Object.values(uniqueMap);
            let metadataMap = {};
            for (let card of uniqueCards) {
                metadataMap[card.uri] = await cassUtil.methods.getMetadata(card.uri);
                // console.log(">> GETTING METADATA FOR:", card.uri, metadataMap[card.uri]);
            }

            let assignMetadata = (card) => {
                let metadata = metadataMap[card.uri];
                if (metadata !== undefined) {
                    card.thumbnailSrc = metadata.thumbnailSrc;
                    if (metadata.tags !== undefined) {
                        let tags = metadata.tags.split(",");
                        card.badges = tags.map(tag => ({
                            title: tag,
                            color: "green"
                        }));
                    }
                    // console.log(card.thumbnailSrc);
                }
                return card;
            };

            this.recentCards = recCards.map(assignMetadata);
            this.favoriteCards = favCards.map(assignMetadata);
            this.popularCards = popCards.map(assignMetadata);
        }
    },
    watch: {
        loggedOnPerson: function() {
            this.availableIdentities = window.EcIdentityManager.default.ids;
        }
    },
    mounted() {
        this.loadForMe();
        this.availableIdentities = window.EcIdentityManager.default.ids;
    }
};
</script>
<style module>
.adlAdvancedDistributedLearnIcon {
    width: 32px;
    position: relative;
    height: 32px;
    object-fit: cover;
}

.barsIcon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.inputText {
    flex: 1;
    position: relative;
    line-height: 125%;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.input {
    align-self: stretch;
    border-radius: 8px;
    background-color: #faf9fb;
    border: 1px solid #d6d2db;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 16px;
}

.inputField {
    width: 402px;
    background-color: #faf9fb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.logoSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 22px;
}

.notificationIcon {
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.avatarIcon {
    width: 32px;
    border-radius: 16px;
    height: 32px;
    object-fit: cover;
}

.rightContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 0px 0px 16px;
    gap: 12px;
}

.navbar {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
}

.divider {
    flex: 1;
    position: relative;
    background-color: #e8e6eb;
    height: 1px;
}

.separator {
    align-self: stretch;
    height: 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.applicationUiNavbar {
    align-self: stretch;
    background-color: #fff;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo {
    width: 232px;
    height: 36px;
    display: none;
}

.homeIcon {
    width: 16px;
    position: relative;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.pages {
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.leftContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.chevronDownIcon {
    width: 28px;
    position: relative;
    height: 28px;
    overflow: hidden;
    flex-shrink: 0;
}

.icon {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.overview {
    align-self: stretch;
    border-radius: 8px;
    background-color: #f4f3f6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 8px;
}

.pages1 {
    width: 232px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 8px;
    box-sizing: border-box;
}

.chevronUpIcon {
    width: 12px;
    position: relative;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}

.sales {
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 8px;
}

.productList {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.submenu {
    width: 226px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0px 0px 0px 36px;
    box-sizing: border-box;
    gap: 16px;
}

.multidropdown {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.authentication {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 8px;
}

.main {
    align-self: stretch;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 24px;
    gap: 8px;
}

.text {
    position: relative;
    line-height: 16px;
    font-weight: 500;
}

.badge {
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
}

.xOutlineIcon {
    width: 10px;
    position: relative;
    height: 10px;
}

.icon4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.previewTheNew {
    align-self: stretch;
    position: relative;
    line-height: 150%;
}

.turnNewFeatures {
    align-self: stretch;
    position: relative;
    text-decoration: underline;
    line-height: 150%;
    font-family: Inter;
}

.text1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
    font-size: 14px;
    color: #42167a;
}

.content2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.cta {
    align-self: stretch;
    border-radius: 6px;
    background-color: #e5dcf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    text-align: center;
    font-size: 12px;
    color: #2d065d;
    font-family: Roboto;
}

.icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.sidebarBottomIcons {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.content1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 0px;
    gap: 24px;
}

.welcomeX {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.pageHeader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
}

.pageHeaderWrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1275px;
    height: 56px;
}

.contentLayoutsInner {
    width: 1275px;
    position: relative;
    height: 56px;
}

.heading {
    align-self: stretch;
    position: relative;
    line-height: 28px;
}

.supportingText {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    color: #6b7280;
}

.arrowRightIcon {
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.button {
    border-radius: 8px;
    background-color: #562990;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    gap: 8px;
}

.button1 {
    width: 124px;
    border-radius: 8px;
    border: 1px solid #e8e6eb;
    box-sizing: border-box;
    height: 50px;
    display: none;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 16px;
    color: #fff;
}

.titleDesc {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    gap: 24px;
}

.videoIcon {
    flex: 1;
    border-radius: 6px;
    max-width: 100%;
    overflow: hidden;
    height: 244px;
    object-fit: cover;
}

.container1 {
    align-self: stretch;
    border-radius: 8px;
    background-color: #fff;
    height: 288px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
    font-family: Roboto;
}

.b {
    position: relative;
    line-height: 24px;
}

.headingPercent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.thisWeekVisitors {
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: Roboto;
    color: #6b7280;
}

.leftContent8 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
}

.arrowNarrowRightIcon {
    width: 14px;
    position: relative;
    height: 14px;
    overflow: hidden;
    flex-shrink: 0;
}

.button2 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    gap: 8px;
    font-size: 14px;
    color: #562990;
}

.headerLg {
    position: absolute;
    top: 0px;
    left: 16px;
    width: 1243px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.cardHeaderIcon {
    align-self: stretch;
    border-radius: 8px;
    max-width: 100%;
    overflow: hidden;
    height: 192px;
    flex-shrink: 0;
    object-fit: contain;
}

.header1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 16px 0px;
}

.role {
    width: 227px;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.preview {
    align-self: stretch;
    position: relative;
    line-height: 150%;
    font-family: Roboto;
}

.titleAndText {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.text4 {
    position: relative;
    line-height: 16px;
}

.badge1 {
    border-radius: 6px;
    background-color: #bed8e0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    color: #145973;
}

.badge2 {
    border-radius: 6px;
    background-color: #e5dcf4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
}

.socialIcons {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: center;
    font-size: 12px;
    color: #2d065d;
    font-family: Roboto;
}

.content4 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.cardBody {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
}

.profileCard {
    flex: 1;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    height: 401px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.nameRole1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.titleAndText1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 32px;
}

.cardBody1 {
    align-self: stretch;
    height: 190px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
}

.role2 {
    width: 226px;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.badge7 {
    border-radius: 6px;
    background-color: #bed8e0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
}

.badge8 {
    border-radius: 6px;
    background-color: #d5c6e9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    color: #2d065d;
}

.socialIcons2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: center;
    font-size: 12px;
    color: #145973;
    font-family: Roboto;
}

.cardBody2 {
    align-self: stretch;
    height: 193px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
}

.profileCard2 {
    flex: 1;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.header4 {
    align-self: stretch;
    height: 209px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 16px 0px;
    box-sizing: border-box;
}

.titleAndText3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 55px;
}

.cardBody3 {
    align-self: stretch;
    height: 192px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    box-sizing: border-box;
}

.cards {
    width: 1147px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.nav {
    border-radius: 999px;
    background-color: #562990;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
}

.carousel {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    height: 482px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}

.carouselWrapper {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 1211px;
    height: 482px;
    font-size: 16px;
    color: #6b7280;
}

.headerLgParent {
    width: 1259px;
    position: relative;
    height: 538px;
}

.headerLg1 {
    position: absolute;
    top: 0px;
    left: 8px;
    width: 1243px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.role4 {
    width: 227px;
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: Roboto;
    color: #6b7280;
    display: flex;
    align-items: center;
}

.role6 {
    width: 226px;
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-family: Roboto;
    color: #6b7280;
    display: flex;
    align-items: center;
}

.carousel1 {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    height: 380px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}

.carouselContainer {
    position: absolute;
    top: 56px;
    left: 0px;
    width: 1211px;
    height: 380px;
    font-size: 20px;
    color: #111928;
}

.headerLgGroup {
    width: 1251px;
    position: relative;
    height: 436px;
}

.role8 {
    width: 227px;
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: #6b7280;
    display: flex;
    align-items: center;
}

.role10 {
    width: 226px;
    position: relative;
    font-size: 16px;
    line-height: 150%;
    font-weight: 500;
    color: #6b7280;
    display: flex;
    align-items: center;
}

.containerParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 24px;
    font-family: Inter;
}

.contentLayouts {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}

.content3 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    font-size: 36px;
    color: #1b1128;
    font-family: 'Roboto Flex';
}

.container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 16px;
    color: #111928;
}

.landingPage {
    width: calc(100%-20%);
    /* Full width minus sidebar width */
    height: calc(100%-10%);
    /* Full height minus topbar  */

    position: relative;
    background-color: #f4f3f6;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #6b7280;
    font-family: Inter;
}
</style>