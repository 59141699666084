<template>
    <div :class="$style.input">
        <div :class="$style.content">
            <img
                :class="$style.searchOutlineIcon"
                alt=""
                src="./search-outline.svg">
            <div :class="$style.inputText">
                Search
            </div>
        </div>
    </div>
</template>
<script lang="ts">
    import {defineComponent} from 'vue';
    
    
    export default defineComponent({
        name: "Input"
    });
          </script>
          <style module>.searchOutlineIcon {
          width: 20px;
          position: relative;
          height: 20px;
          overflow: hidden;
          flex-shrink: 0;
    }
          .inputText {
                flex: 1;
                position: relative;
                line-height: 125%;
          }
          .content {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
          }
          .input {
                width: 100%;
                position: relative;
                border-radius: 8px;
                background-color: #faf9fb;
                border: 1px solid #d6d2db;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 12px 16px;
                text-align: left;
                font-size: 14px;
                color: #6b7280;
                font-family: Inter;
          }
    
    </style>