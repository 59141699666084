<template>
    <div :class="$style.crosswalk">
        <div :class="$style.container">
            <div :class="$style.contentLayouts">
                <div :class="$style.contentLayoutsInner">
                    <div :class="$style.pageHeaderWrapper">
                        <div :class="$style.pageHeader">
                            <div :class="$style.welcomeX">
                                Crosswalk
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style.tableWrapper">
                    <div :class="$style.table">
                        <div :class="$style.tableHeader">
                            <div :class="$style.firstLevelWrapper">
                                <div :class="$style.firstLevel">
                                    <div :class="$style.firstLevelInner">
                                        <div :class="$style.groupWrapper">
                                            <div :class="$style.headingWrapper">
                                                <div :class="$style.heading">
                                                    <b :class="$style.text2">Framework Alignment</b>
                                                    <div :class="$style.text3" />
                                                    <div :class="$style.info" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.groupParent">
                                <div :class="$style.stepperNavigationParent">
                                    <div :class="$style.stepperNavigation">
                                        <div :class="$style.navItem">
                                            <div :class="$style.navLink">
                                                Select source
                                            </div>
                                        </div>
                                        <img
                                            :class="$style.lineIcon"
                                            alt=""
                                            src="./assets/Line.svg">
                                        <div :class="$style.navItem1">
                                            <div :class="$style.navLink">
                                                Select Target
                                            </div>
                                        </div>
                                        <img
                                            :class="$style.lineIcon"
                                            alt=""
                                            src="./assets/Line.svg">
                                        <div :class="$style.navItem">
                                            <div :class="$style.navLink">
                                                Align Frameworks
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        :class="$style.lineIcon2"
                                        alt=""
                                        src="./assets/Line.svg">
                                    <div :class="$style.navItem3">
                                        <div :class="$style.navLink">
                                            Save & Review
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.line" />
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style.addFrameworkUploaded">
                    <div :class="$style.modalHeader">
                        <div :class="$style.heading1">
                            <div :class="$style.selectSource">
                                Select source
                            </div>
                        </div>
                        <div :class="$style.heading2">
                            <div :class="$style.selectSource">
                                Select target
                            </div>
                        </div>
                    </div>
                    <div :class="$style.modalBodyWrapper">
                        <div :class="$style.modalBody">
                            <div :class="$style.row">
                                <div
                                    :class="$style.selectInput"
                                    @click="onOverviewContainerClick">
                                    <div :class="$style.searchWrapper">
                                        <div :class="$style.search">
                                            <div :class="$style.button">
                                                <div :class="$style.pages">
                                                    Competency frameworks
                                                </div>
                                                <img
                                                    :class="$style.barsIcon"
                                                    alt=""
                                                    src="./assets/chevron-down.svg">
                                            </div>
                                            <div :class="$style.inputField1">
                                                <div :class="$style.content3">
                                                    <div :class="$style.placeholder">
                                                        Search Competency frameworks
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.button1">
                                                <img
                                                    :class="$style.barsIcon"
                                                    alt=""
                                                    src="./assets/search-outline.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.selectInput1">
                                    <div :class="$style.searchWrapper">
                                        <div :class="$style.search">
                                            <div :class="$style.button">
                                                <div :class="$style.pages">
                                                    Competency frameworks
                                                </div>
                                                <img
                                                    :class="$style.barsIcon"
                                                    alt=""
                                                    src="./assets/chevron-down.svg">
                                            </div>
                                            <div :class="$style.inputField1">
                                                <div :class="$style.content3">
                                                    <div :class="$style.placeholder">
                                                        Search Competency frameworks
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.button1">
                                                <img
                                                    :class="$style.barsIcon"
                                                    alt=""
                                                    src="./assets/search-outline.svg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style.groupContainer">
                    <div :class="$style.frameWrapper">
                        <div :class="$style.tableHeaderParent">
                            <div :class="$style.tableHeader">
                                <div :class="$style.header">
                                    <div :class="$style.groupFrame">
                                        <div :class="$style.headingContainer">
                                            <div :class="$style.heading">
                                                <b :class="$style.text2">Source</b>
                                                <div :class="$style.text3" />
                                                <div :class="$style.info" />
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.frameContainer">
                                        <div :class="$style.xWrapper">
                                            <img
                                                :class="$style.chevronUpIcon"
                                                alt=""
                                                src="./assets/x.svg">
                                        </div>
                                    </div>
                                </div>
                                <div :class="$style.heading4">
                                    <div :class="$style.selectSource">
                                        Select source
                                    </div>
                                </div>
                                <div :class="$style.tableHeaderInner">
                                    <div :class="$style.groupWrapper1">
                                        <div :class="$style.groupDiv">
                                            <div :class="$style.inputButtonsWrapper">
                                                <div :class="$style.inputButtons">
                                                    <div :class="$style.input1">
                                                        <div :class="$style.inputField3">
                                                            <div :class="$style.input2">
                                                                <div :class="$style.content5">
                                                                    <img
                                                                        :class="$style.homeIcon"
                                                                        alt=""
                                                                        src="./assets/search-outline.svg">
                                                                    <div :class="$style.placeholder">
                                                                        Search
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div :class="$style.button4">
                                                            <div :class="$style.pages">
                                                                Search
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="$style.button5">
                                                        <img
                                                            :class="$style.chevronUpIcon"
                                                            alt=""
                                                            src="./assets/filter.svg">
                                                        <div :class="$style.pages">
                                                            Filters
                                                        </div>
                                                    </div>
                                                    <div :class="$style.button5">
                                                        <img
                                                            :class="$style.chevronUpIcon"
                                                            alt=""
                                                            src="./assets/switch-vertical.svg">
                                                        <div :class="$style.pages">
                                                            Sort
                                                        </div>
                                                    </div>
                                                    <div :class="$style.button7">
                                                        <img
                                                            :class="$style.chevronUpIcon"
                                                            alt=""
                                                            src="./assets/switch-vertical.svg">
                                                        <div :class="$style.pages">
                                                            Expand all
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.contentWrapper">
                                <div :class="$style.content6" />
                            </div>
                        </div>
                    </div>
                    <div :class="$style.frameWrapper1">
                        <div :class="$style.groupWrapper2">
                            <div :class="$style.contentContainer">
                                <div :class="$style.content7">
                                    <div :class="$style.contentInner">
                                        <div :class="$style.groupWrapper3">
                                            <div :class="$style.contentFrame">
                                                <div :class="$style.content8">
                                                    <div :class="$style.tasksWrapper">
                                                        <div :class="$style.tasks">
                                                            <div :class="$style.tasksInner">
                                                                <div :class="$style.groupParent1">
                                                                    <div :class="$style.groupWrapper4">
                                                                        <div :class="$style.headingFrame">
                                                                            <div :class="$style.heading">
                                                                                <div :class="$style.welcomeX">
                                                                                    Source
                                                                                </div>
                                                                                <div :class="$style.text3" />
                                                                                <div :class="$style.info" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div :class="$style.frameContainer">
                                                                        <div :class="$style.xWrapper">
                                                                            <img
                                                                                :class="$style.chevronUpIcon"
                                                                                alt=""
                                                                                src="./assets/x.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.contentChild">
                                        <div :class="$style.fileUploadWrapper">
                                            <div :class="$style.fileUpload">
                                                <div :class="$style.dropFilesToUpload">
                                                    <img
                                                        :class="$style.barsIcon"
                                                        alt=""
                                                        src="./assets/plus.svg">
                                                    <div :class="$style.previewTheNew">
                                                        <span :class="$style.selectASource">Select a Source competency framework</span>
                                                        <span :class="$style.toApplyAlignment"> to apply alignment</span>
                                                    </div>
                                                    <div :class="$style.svgPngJpg">
                                                        CSV, XML, or JSON
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="$style.frameWrapper3">
                        <div :class="$style.groupParent2">
                            <div :class="$style.frameWrapper4">
                                <div :class="$style.groupWrapper5">
                                    <div :class="$style.contentFrame">
                                        <div :class="$style.content8">
                                            <div :class="$style.tasksWrapper">
                                                <div :class="$style.tasks">
                                                    <div :class="$style.tasksInner">
                                                        <div :class="$style.groupParent1">
                                                            <div :class="$style.groupWrapper6">
                                                                <div :class="$style.headingWrapper1">
                                                                    <div :class="$style.heading">
                                                                        <div :class="$style.welcomeX">
                                                                            Target
                                                                        </div>
                                                                        <div :class="$style.text3" />
                                                                        <div :class="$style.info" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div :class="$style.frameContainer">
                                                                <div :class="$style.xWrapper">
                                                                    <img
                                                                        :class="$style.chevronUpIcon"
                                                                        alt=""
                                                                        src="./assets/x.svg">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.contentWrapper2">
                                <div :class="$style.content10">
                                    <div :class="$style.contentInner1">
                                        <div :class="$style.frameWrapper6">
                                            <div :class="$style.fileUploadContainer">
                                                <div :class="$style.fileUpload">
                                                    <div :class="$style.dropFilesToUpload">
                                                        <img
                                                            :class="$style.barsIcon"
                                                            alt=""
                                                            src="./assets/plus.svg">
                                                        <div :class="$style.previewTheNew">
                                                            <span :class="$style.selectASource">Select a Target competency framework</span>
                                                            <span :class="$style.toApplyAlignment"> to apply alignment</span>
                                                        </div>
                                                        <div :class="$style.svgPngJpg">
                                                            CSV, XML, or JSON
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
          <script lang="ts">
                import {defineComponent} from 'vue';
                
                
                export default defineComponent({
                      name: "Crosswalk",
                      methods: {
                            onOverviewContainerClick() {
                                  // Add your code here
                        }
                    }
                });
                            </script>
                            
                            <style module>.adlAdvancedDistributedLearnIcon {
                      width: 32px;
                      position: relative;
                      height: 32px;
                      object-fit: cover;
                }
                      .barsIcon {
                            width: 20px;
                            position: relative;
                            height: 20px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .inputText {
                            flex: 1;
                            position: relative;
                            line-height: 125%;
                      }
                      .content {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 10px;
                      }
                      .input {
                            align-self: stretch;
                            border-radius: 8px;
                            background-color: #faf9fb;
                            border: 1px solid #d6d2db;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 12px 16px;
                      }
                      .inputField {
                            width: 402px;
                            background-color: #faf9fb;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .logoSearch {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 22px;
                      }
                      .notificationIcon {
                            border-radius: 16px;
                            background-color: #fff;
                            overflow: hidden;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 4px;
                      }
                      .avatarIcon {
                            width: 32px;
                            border-radius: 16px;
                            height: 32px;
                            object-fit: cover;
                      }
                      .rightContent {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            padding: 0px 0px 0px 16px;
                            gap: 12px;
                      }
                      .navbar {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0px 16px;
                      }
                      .divider {
                            flex: 1;
                            position: relative;
                            background-color: #e8e6eb;
                            height: 1px;
                      }
                      .separator {
                            align-self: stretch;
                            height: 1px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .applicationUiNavbar {
                            align-self: stretch;
                            background-color: #fff;
                            height: 64px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .logo {
                            width: 232px;
                            height: 36px;
                            display: none;
                      }
                      .homeIcon {
                            width: 16px;
                            position: relative;
                            height: 16px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .pages {
                            position: relative;
                            line-height: 150%;
                            font-weight: 500;
                      }
                      .leftContent {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .chevronDownIcon {
                            width: 28px;
                            position: relative;
                            height: 28px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .icon {
                            display: none;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                      }
                      .overview {
                            align-self: stretch;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                            cursor: pointer;
                      }
                      .pages1 {
                            width: 232px;
                            display: none;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                            box-sizing: border-box;
                      }
                      .chevronUpIcon {
                            width: 12px;
                            position: relative;
                            height: 12px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .sales {
                            align-self: stretch;
                            border-radius: 8px;
                            background-color: #f4f3f6;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                      }
                      .productList {
                            align-self: stretch;
                            background-color: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            cursor: pointer;
                      }
                      .billing {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .subMenu2 {
                            position: relative;
                            line-height: 150%;
                      }
                      .submenu {
                            width: 226px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            padding: 0px 0px 0px 36px;
                            box-sizing: border-box;
                            gap: 16px;
                      }
                      .multidropdown {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 8px;
                      }
                      .authentication {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                      }
                      .main {
                            align-self: stretch;
                            border-bottom: 1px solid #e5e7eb;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 0px 24px;
                            gap: 8px;
                      }
                      .text {
                            position: relative;
                            line-height: 16px;
                            font-weight: 500;
                      }
                      .badge {
                            border-radius: 6px;
                            background-color: #d5c6e9;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 2px 10px;
                      }
                      .xOutlineIcon {
                            width: 10px;
                            position: relative;
                            height: 10px;
                      }
                      .icon4 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                      }
                      .header {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                      }
                      .previewTheNew {
                            align-self: stretch;
                            position: relative;
                            line-height: 150%;
                      }
                      .turnNewFeatures {
                            align-self: stretch;
                            position: relative;
                            text-decoration: underline;
                            line-height: 150%;
                            font-family: Inter;
                      }
                      .text1 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                            text-align: left;
                            font-size: 14px;
                            color: #42167a;
                      }
                      .content2 {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .cta {
                            align-self: stretch;
                            border-radius: 6px;
                            background-color: #e5dcf4;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 16px;
                            text-align: center;
                            font-size: 12px;
                            color: #2d065d;
                            font-family: Roboto;
                      }
                      .icons {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 20px;
                      }
                      .sidebarBottomIcons {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            justify-content: center;
                      }
                      .content1 {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 16px 0px;
                            gap: 24px;
                      }
                      .applicationUiPrimarySidebar {
                            align-self: stretch;
                            width: 256.5px;
                            background-color: #fff;
                            border-right: 1px solid #e8e6eb;
                            box-sizing: border-box;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 12px;
                      }
                      .welcomeX {
                            position: relative;
                            line-height: 24px;
                            font-weight: 600;
                      }
                      .pageHeader {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 1275px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 16px;
                            box-sizing: border-box;
                      }
                      .pageHeaderWrapper {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 1275px;
                            height: 56px;
                      }
                      .contentLayoutsInner {
                            width: 1275px;
                            position: relative;
                            height: 56px;
                            font-size: 36px;
                            font-family: 'Roboto Flex';
                      }
                      .text2 {
                            position: relative;
                            line-height: 24px;
                      }
                      .text3 {
                            position: relative;
                            font-size: 16px;
                            line-height: 150%;
                            font-weight: 500;
                            color: #6b7280;
                      }
                      .info {
                            width: 14px;
                            position: relative;
                            height: 14px;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: none;
                      }
                      .heading {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;
                      }
                      .headingWrapper {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 268px;
                            height: 24px;
                      }
                      .groupWrapper {
                            position: absolute;
                            top: 0.5px;
                            left: 0px;
                            width: 268px;
                            height: 24px;
                      }
                      .firstLevelInner {
                            align-self: stretch;
                            position: relative;
                            height: 25px;
                      }
                      .firstLevel {
                            width: 1227px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .firstLevelWrapper {
                            align-self: stretch;
                            height: 24px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: center;
                      }
                      .navItem {
                            align-self: stretch;
                            border-radius: 6px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .lineIcon {
                            flex: 1;
                            position: relative;
                            max-width: 100%;
                            overflow: hidden;
                            height: 0.1px;
                            object-fit: contain;
                      }
                      .navItem1 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .navLink {
                            position: relative;
                            line-height: 125%;
                            font-weight: 500;
                      }
                      .stepperNavigation {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 840.1px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .lineIcon2 {
                            position: absolute;
                            top: 9.98px;
                            left: 860.09px;
                            width: 224.4px;
                            height: 0px;
                            object-fit: contain;
                      }
                      .navItem3 {
                            position: absolute;
                            top: 0px;
                            left: 1104.49px;
                            border-radius: 6px;
                            width: 122.5px;
                            height: 20px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .stepperNavigationParent {
                            width: 1227px;
                            position: relative;
                            height: 20px;
                            z-index: 0;
                      }
                      .line {
                            width: 145px;
                            position: absolute;
                            margin: 0 !important;
                            top: 9.5px;
                            left: 129.5px;
                            border-top: 1px solid #e8e6eb;
                            box-sizing: border-box;
                            height: 1px;
                            z-index: 1;
                      }
                      .groupParent {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            position: relative;
                            gap: 16px;
                            font-size: 14px;
                            color: #6b7280;
                      }
                      .tableHeader {
                            align-self: stretch;
                            border-radius: 8px 8px 0px 0px;
                            background-color: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 24px;
                            gap: 24px;
                      }
                      .table {
                            width: 1275px;
                            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
                            border-radius: 8px;
                            background-color: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .tableWrapper {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 24px;
                            color: #111928;
                      }
                      .selectSource {
                            position: relative;
                            line-height: 150%;
                            font-weight: 600;
                      }
                      .heading1 {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .heading2 {
                            align-self: stretch;
                            width: 608px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .modalHeader {
                            align-self: stretch;
                            border-bottom: 1px solid #e5e7eb;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 16px 0px;
                      }
                      .button {
                            width: 213px;
                            border-radius: 8px 0px 0px 8px;
                            background-color: #f4f3f6;
                            border: 1px solid #d6d2db;
                            box-sizing: border-box;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 8px 19px 8px 20px;
                            gap: 8px;
                      }
                      .placeholder {
                            flex: 1;
                            position: relative;
                            line-height: 150%;
                      }
                      .content3 {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .inputField1 {
                            flex: 1;
                            background-color: #faf9fb;
                            border: 1px solid #d6d2db;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 8px 10px;
                            color: #6b7280;
                      }
                      .button1 {
                            align-self: stretch;
                            width: 44px;
                            border-radius: 0px 8px 8px 0px;
                            background-color: #562990;
                            border: 1px solid #562990;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 8px;
                      }
                      .search {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 608px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .searchWrapper {
                            width: 608px;
                            position: relative;
                            height: 37px;
                      }
                      .selectInput {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            cursor: pointer;
                      }
                      .selectInput1 {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .row {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .modalBody {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 1235px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 16px 0px 0px;
                            box-sizing: border-box;
                      }
                      .modalBodyWrapper {
                            width: 1235px;
                            position: relative;
                            height: 53px;
                            font-size: 14px;
                            font-family: Roboto;
                      }
                      .addFrameworkUploaded {
                            align-self: stretch;
                            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
                            border-radius: 8px;
                            background-color: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            padding: 0px 20px 20px;
                      }
                      .headingContainer {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 92px;
                            height: 24px;
                      }
                      .groupFrame {
                            width: 92px;
                            position: relative;
                            height: 24px;
                      }
                      .xWrapper {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .frameContainer {
                            width: 12px;
                            position: relative;
                            height: 12px;
                      }
                      .heading4 {
                            width: 379px;
                            height: 27px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 18px;
                            color: #1b1128;
                      }
                      .content5 {
                            width: 332px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 10px;
                      }
                      .input2 {
                            align-self: stretch;
                            border-radius: 8px 0px 0px 8px;
                            background-color: #faf9fb;
                            border: 1px solid #d6d2db;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 8px 16px;
                      }
                      .inputField3 {
                            width: 214px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .button4 {
                            width: 53px;
                            border-radius: 0px 8px 8px 0px;
                            background-color: #562990;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 8px 12px;
                            box-sizing: border-box;
                            color: #fff;
                            font-family: Inter;
                      }
                      .input1 {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            color: #6b7280;
                            font-family: Roboto;
                      }
                      .button5 {
                            border-radius: 8px;
                            background-color: #faf9fb;
                            border: 1px solid #9678bc;
                            overflow: hidden;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 8px 12px;
                            gap: 8px;
                      }
                      .button7 {
                            width: 112px;
                            border-radius: 8px;
                            background-color: #faf9fb;
                            border: 1px solid #9678bc;
                            box-sizing: border-box;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 8px 12px;
                            gap: 8px;
                      }
                      .inputButtons {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .inputButtonsWrapper {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 586px;
                            height: 37px;
                      }
                      .groupDiv {
                            width: 586px;
                            position: relative;
                            height: 37px;
                      }
                      .groupWrapper1 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 587px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .tableHeaderInner {
                            width: 587px;
                            position: relative;
                            height: 37px;
                            font-size: 14px;
                            color: #562990;
                      }
                      .content6 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: #fff;
                            width: 633px;
                            height: 308px;
                      }
                      .contentWrapper {
                            align-self: stretch;
                            position: relative;
                            height: 308px;
                      }
                      .tableHeaderParent {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 44.2px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .frameWrapper {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 44.2px;
                            font-size: 24px;
                      }
                      .headingFrame {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 70px;
                            height: 24px;
                      }
                      .groupWrapper4 {
                            width: 70px;
                            position: relative;
                            height: 24px;
                      }
                      .groupParent1 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 600.8px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                      }
                      .tasksInner {
                            width: 600.8px;
                            position: relative;
                            height: 24px;
                      }
                      .tasks {
                            width: 633px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: flex-start;
                            padding: 16px;
                            box-sizing: border-box;
                      }
                      .tasksWrapper {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .content8 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: #fff;
                            width: 633px;
                            height: 160px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .contentFrame {
                            align-self: stretch;
                            position: relative;
                            height: 160px;
                      }
                      .groupWrapper3 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 60px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .contentInner {
                            width: 633px;
                            position: relative;
                            height: 60px;
                      }
                      .selectASource {
                            font-weight: 600;
                      }
                      .toApplyAlignment {
                            font-family: Roboto;
                      }
                      .svgPngJpg {
                            width: 101px;
                            position: relative;
                            font-size: 12px;
                            line-height: 16px;
                            font-family: Roboto;
                            display: none;
                      }
                      .dropFilesToUpload {
                            width: 440px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 8px;
                      }
                      .fileUpload {
                            align-self: stretch;
                            border-radius: 8px;
                            background-color: #faf9fb;
                            border: 2px dashed #e8e6eb;
                            box-sizing: border-box;
                            height: 411px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                      }
                      .fileUploadWrapper {
                            align-self: stretch;
                            height: 381px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .contentChild {
                            align-self: stretch;
                            height: 428px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 17px;
                            box-sizing: border-box;
                            text-align: center;
                            font-size: 14px;
                            color: #6b7280;
                      }
                      .content7 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: #fff;
                            width: 633px;
                            height: 507px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 10px;
                      }
                      .contentContainer {
                            align-self: stretch;
                            position: relative;
                            height: 507px;
                      }
                      .groupWrapper2 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 38.4px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .frameWrapper1 {
                            position: absolute;
                            top: 0px;
                            left: 0.25px;
                            width: 633px;
                            height: 38.4px;
                      }
                      .headingWrapper1 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 65px;
                            height: 24px;
                      }
                      .groupWrapper6 {
                            width: 65px;
                            position: relative;
                            height: 24px;
                      }
                      .groupWrapper5 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 61px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .frameWrapper4 {
                            width: 633px;
                            position: relative;
                            height: 61px;
                      }
                      .fileUploadContainer {
                            align-self: stretch;
                            height: 409px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .frameWrapper6 {
                            align-self: stretch;
                            width: 633px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 17px;
                            box-sizing: border-box;
                      }
                      .contentInner1 {
                            align-self: stretch;
                            height: 426px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .content10 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: #fff;
                            width: 633px;
                            height: 447px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .contentWrapper2 {
                            align-self: stretch;
                            position: relative;
                            height: 447px;
                            text-align: center;
                            font-size: 14px;
                            color: #6b7280;
                      }
                      .groupParent2 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 633px;
                            height: 607px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .frameWrapper3 {
                            position: absolute;
                            top: 0px;
                            left: 636.25px;
                            width: 633px;
                            height: 607px;
                      }
                      .groupContainer {
                            width: 1269.3px;
                            position: relative;
                            height: 607px;
                            color: #111928;
                      }
                      .contentLayouts {
                            width: 1307px;
                            height: 905px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 16px;
                            font-size: 18px;
                            color: #1b1128;
                      }
                      .container {
                            align-self: stretch;
                            height: 905px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            font-size: 16px;
                            color: #111928;
                      }
                      .crosswalk {
                            width: 100%;
                            position: relative;
                            background-color: #f4f3f6;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: left;
                            font-size: 14px;
                            color: #6b7280;
                            font-family: Inter;
                      }
                
                </style>