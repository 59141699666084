<template>
    <img
        :class="$style.barsIcon"
        alt=""
        src="./bars.svg">
</template>
    <script lang="ts">
    import {defineComponent} from 'vue';


    export default defineComponent({
            name: "BarsIcon"
        });
            </script>
            
            <style module>.barsIcon {
            width: 100%;
            position: relative;
            height: 20px;
            overflow: hidden;
            flex-shrink: 0;
    }

    </style>