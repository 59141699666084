<template>
    <div :class="$style[badgeClass]">
        <div :class="$style.badge">
            <div :class="$style.page">
                {{ title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        title: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: false,
            default: 'green',
            validator(value) {
                return ['green', 'purple'].includes(value);
            }
        }

    },
    computed: {
        badgeClass() {
            console.log("This is the value of color: " + this.color);
            return this.color;
        }
    }

};
</script>

<style module>

.green {
border-radius: 6px;
align-self: stretch;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
text-align: center;
font-size: 12px;
color: #145973;
font-family: Roboto;
background-color: #bed8e0;
}
.purple {
border-radius: 6px;
align-self: stretch;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
gap: 12px;
text-align: center;
font-size: 12px;
color: #2d065d;
font-family: Roboto;
background-color: #d5c6e9;
}

.badge {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 2px 12px;
}

.page {
position: relative;
line-height: 150%;
font-weight: 500;}
</style>