<template>
    <img
        :class="$style.adlAdvancedDistributedLearnIcon"
        alt=""
        src="./ADL-Advanced-Distributed-Learning 1.png">
</template>
<script lang="ts">
    import {defineComponent} from 'vue';
    
    
    export default defineComponent({
        name: "ADLAdvancedDistributedLearnIcon"
    });
          </script>
          
          <style module>.adlAdvancedDistributedLearnIcon {
          width: 100%;
          position: relative;
          height: 32px;
          object-fit: cover;
    }
    
    </style>