<template>
    <div :class="$style.applicationUiNavbar">
        <div :class="$style.navbar">
            <div :class="$style.logoSearch">
                <img
                    :class="$style.adlAdvancedDistributedLearnIcon"
                    alt=""
                    src="./assets/ADL-Advanced-Distributed-Learning 1.png">
                <img
                    :class="$style.barsIcon"
                    alt=""
                    src="./assets/bars.svg">
                <div :class="$style.inputField">
                    <div :class="$style.input">
                        <div :class="$style.content">
                            <img
                                :class="$style.barsIcon"
                                alt=""
                                src="./assets/search-outline.svg">
                            <div :class="$style.inputText">
                                Search
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="$style.rightContent">
                <div :class="$style.notificationIcon">
                    <img
                        :class="$style.barsIcon"
                        alt=""
                        src="./assets/bell.svg">
                </div>
                <div :class="$style.notificationIcon">
                    <img
                        :class="$style.barsIcon"
                        alt=""
                        src="./assets/grid.svg">
                </div>
                <img
                    :class="$style.avatarIcon"
                    alt=""
                    src="./assets/Avatar.jpeg">
            </div>
        </div>
        <div :class="$style.separator">
            <div :class="$style.divider" />
        </div>
    </div>
</template>
<script lang="ts">
      import {defineComponent} from 'vue';
      
      
      export default defineComponent({
            name: "ApplicationUINavbar"
      });
              </script>
              <style module>.adlAdvancedDistributedLearnIcon {
              width: 32px;
              position: relative;
              height: 32px;
              object-fit: cover;
      }
              .barsIcon {
                      width: 20px;
                      position: relative;
                      height: 20px;
                      overflow: hidden;
                      flex-shrink: 0;
              }
              .inputText {
                      flex: 1;
                      position: relative;
                      line-height: 125%;
              }
              .content {
                      flex: 1;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      gap: 10px;
              }
              .input {
                      align-self: stretch;
                      border-radius: 8px;
                      background-color: #faf9fb;
                      border: 1px solid #d6d2db;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      padding: 12px 16px;
              }
              .inputField {
                      width: 402px;
                      background-color: #faf9fb;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: flex-start;
              }
              .logoSearch {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      gap: 22px;
              }
              .notificationIcon {
                      border-radius: 16px;
                      background-color: #fff;
                      overflow: hidden;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      padding: 4px;
              }
              .avatarIcon {
                      width: 32px;
                      border-radius: 16px;
                      height: 32px;
                      object-fit: cover;
              }
              .rightContent {
                      flex: 1;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-end;
                      padding: 0px 0px 0px 16px;
                      gap: 12px;
              }
              .navbar {
                      align-self: stretch;
                      flex: 1;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      padding: 0px 16px;
              }
              .divider {
                      flex: 1;
                      position: relative;
                      background-color: #e8e6eb;
                      height: 1px;
              }
              .separator {
                      align-self: stretch;
                      height: 1px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
              }
              .applicationUiNavbar {
                  width: calc(100%-20%); /* Full width minus sidebar width */
                      position: fixed;
                  top: 0;
              /*    left: 250px; /* Align with the sidebar */
                  height: 10%; /* Height of the top navbar, I made a 10% but can be adjust*/
                      background-color: #fff;
                      z-index: 1000; /* Higher than sidebar */
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                      padding: 0px 16px;
box-sizing: border-box;
                      text-align: left;
                      font-size: 14px;
                      color: #6b7280;
                      font-family: Inter;
              }
      
      </style>